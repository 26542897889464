<template lang="pug">
.main-layer#app
	Header
	router-view
	Footer
</template>

<script>
export default {
	name: 'App',
};
</script>
<style lang="scss">
	@import 'styles/mixin';
</style>