// src/router/index.js
import Vue from 'vue';
import Router from 'vue-router';
import Accounts from '@/pages/Accounts/';
import Instruction from '@/pages/Instruction/';
import ThankYou from '@/pages/ThankYou/';

Vue.use(Router);

export default new Router({
    mode: 'history',
    base: '/',
    routes: [
        {
            path: '/accounts',
            name: 'Accounts',
            component: Accounts,
            props: route => ({ code: route.query.code }),
        },
        {
            path: '/',
            name: 'Instruction',
            component: Instruction
        },
        {
            path: '/success',
            name: 'ThankYou',
            component: ThankYou
        },
    ],
});

