import { toRaw } from 'vue'
import axios from 'axios'
// import router from './router'
// import { useAuthStore } from '@/store/auth'

export const apiClient = axios.create({
	// baseURL: 'http://localhost:8080/',
	baseURL: process.env.VUE_APP_API_BASE_URL,
	headers: {
		'Content-Type': 'application/json'
	}
	// validateStatus: function (status) {
	// 	return status < 401;
	// }
})

// Add a response interceptor to format errors
apiClient.interceptors.response.use(
	(response) => { return response },
	(error) => {
		if (error.response && (error.response.status === 400 || error.response.status === 401)) {
			if ('detail' in error.response.data) {
				error.response.data.non_field_errors = [error.response.data.detail]
				delete error.response.data.detail
			}
		}
		// if (error.response && (error.response.status === 403)) {
		// 	const authStore = useAuthStore()
		// 	authStore.access_token = '';
		// 	router.push({ name: 'Login' })
		// }
		return Promise.reject(error)
	})

// Use interceptor to inject the token to requests
// apiClient.interceptors.request.use(config => {
// 	if (config.url === LOGIN_URL) {
// 		return config
// 	}

// 	const authStore = useAuthStore()
// 	const token = authStore.access_token
// 	if (token !== '') {
// 		config.headers.Authorization = 'Bearer ' + token
// 	}

// 	return config
// })

function apiHandler (method, url, data) {
	return new Promise((resolve, reject) => {
		apiClient({
			method,
			url,
			data: toRaw(data)
		})
			.then(resp => {
				if(resp.data.messages){
					console.log(resp.data)
				}
				resolve(resp.data)
			})
			.catch(err => {
				if (err.response) {
					if (err.response.status > 400) {
						console.log(err.response.data)
					}
					reject(err.response.data)
				} else {
					console.log(err)
					reject(err)
				}
			})
	})
}

const _$api = (method, url, data) => { return apiHandler(method, url, data) }
_$api.get = (url, data) => { return apiHandler('get', url, data) }
_$api.post = (url, data) => { return apiHandler('post', url, data) }
_$api.put = (url, data) => { return apiHandler('put', url, data) }
_$api.patch = (url, data) => { return apiHandler('patch', url, data) }
_$api.delete = (url, data) => { return apiHandler('delete', url, data) }

export { _$api as $api }
