<template lang="pug">
header
	.logo
		img(src="@/assets/logo.svg", alt="")
		

</template>

<script>
	export default {
		name: 'Header'
	}
</script>