import Vue from 'vue';
import { createPinia } from 'pinia'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
import { $api } from '@/api_client'
import router from './router';
import App from './App.vue';
import BootstrapVue from 'bootstrap-vue';
import Header from '@/components/header'
import Footer from '@/components/footer'
// import 'bootstrap/dist/css/bootstrap.css';
// import 'bootstrap-vue/dist/bootstrap-vue.css';

Vue.config.productionTip = false;

const pinia = createPinia()
pinia.use(piniaPluginPersistedstate)
Vue.use(pinia)
Vue.use($api)

Vue.use(BootstrapVue);
Vue.component('Header', Header)
Vue.component('Footer', Footer)

new Vue({
  router,
  render: h => h(App),
}).$mount('#app');
